import { useLogout } from "@/utils/logout";
import { EmptyHeader } from "./EmptyHeader";
import { useScaffold } from "@Light/scaffold";
import { useAccount } from "@Light/components/page/account";
import { ReactNode } from "react";

export type ServiceAlreadyActiveProps = {
  title?: string;
};

export const ServiceAlreadyActive: React.FC<ServiceAlreadyActiveProps> = ({
  title,
}) => {
  const logout = useLogout("service_already_active");
  const scaffold = useScaffold();
  const Title = scaffold.page.title;
  const Subtitle = scaffold.page.subtitle;

  const companyName = scaffold.page.companyName;
  const supportEmail = scaffold.page.supportEmail;

  const account = useAccount();
  const location = account.locations.length > 0 ? account.locations[0] : null;
  const finalServiceDate = location?.final_service_date;

  let titleText = title || "Your service is active";
  let subtitleText = `You can manage your service within the ${companyName} platform.`;
  let subtitleText2: ReactNode | null = null;

  if (finalServiceDate) {
    const date = new Date(finalServiceDate);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    titleText = "Your service was cancelled on " + formattedDate;
    subtitleText = `You can view information about your service within the ${companyName} platform.`;

    subtitleText2 = (
      <>
        If you are interested in reactivating your service, please contact
        support at{" "}
        <a
          href={`mailto:${supportEmail}`}
          className="text-blue-600 hover:text-blue-800"
          style={{ whiteSpace: "nowrap" }}
        >
          {supportEmail}
        </a>
      </>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <EmptyHeader />
      <div className="flex justify-center items-center text-center h-full px-4">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-6">
            <Title>{titleText}</Title>
            <Subtitle>{subtitleText}</Subtitle>
            {subtitleText2 && <Subtitle>{subtitleText2}</Subtitle>}
          </div>
          <button
            className="bg-slate-900 text-white rounded rounded-md p-2"
            onClick={logout}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
