import { useScaffold } from "@Light/scaffold";
import { useLight } from "@Light/services/light";
import { UpdateAccount } from "@Light/services/lightTypes";
import { useMutation } from "@Light/utils/mutation";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useAccount } from "@Light/components/page/account";
import dayjs from "dayjs";
import { useState } from "@Light/utils/state";

const formatPhoneNumber = (value: string) => {
  let phoneNumber = value.replace(/\D/g, "");
  if (phoneNumber.length < 4) {
    return phoneNumber;
  }
  if (phoneNumber[0] === "1") {
    phoneNumber = phoneNumber.slice(1);
  }
  const areaCode = `(${phoneNumber.slice(0, 3)})`;
  if (phoneNumber.length < 7) {
    return `${areaCode} ${phoneNumber.slice(3)}`;
  }
  const firstThree = phoneNumber.slice(3, 6);
  return `${areaCode} ${firstThree}-${phoneNumber.slice(6, 10)}`;
};

export type VerifyIdentityProps = {};

export const VerifyIdentity: React.FC<VerifyIdentityProps> = ({}) => {
  const currentDate = useState(dayjs()).val;
  const account = useAccount();
  const scaffold = useScaffold();
  const { useUpdateAccountMutation } = useLight();
  const updateAccount = useMutation<UpdateAccount>(useUpdateAccountMutation);
  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = useCallback(
    handleSubmit(({ birth_month, birth_day, birth_year, ...body }) => {
      updateAccount.mutate({
        birth_date: `${birth_month}/${birth_day}/${birth_year}`,
        ...body,
      });
    }),
    [handleSubmit, updateAccount.mutate],
  );
  const phoneNumberOnChange = useCallback((e: any) => {
    setValue("phone_number", formatPhoneNumber(e.target.value));
  }, []);

  if (updateAccount.isSuccess && account.enrollment.is_identity_verified) {
    if (scaffold.enroll.collectPaymentAfterEnrollment) {
      return <Navigate to="/enroll/confirm-and-enroll" />;
    }

    return <Navigate to="/enroll/setup-payment" />;
  }

  const needsName = scaffold.enroll.steps.verifyIdentityNeedsName;
  const PageBody = scaffold.page.pageBody;
  const MutationButton = scaffold.page.mutationButton;
  const Field = scaffold.system.field;
  const Label = scaffold.system.label;
  const Input = scaffold.system.input;
  const Select = scaffold.system.select;
  return (
    <form onSubmit={onSubmit}>
      <PageBody
        title="Confirm your identity"
        subtitle="Providing your information helps us verify who you are."
        progress="25%"
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            {needsName && (
              <>
                <div className="flex flex-row gap-4">
                  <Field className="flex flex-col gap-1 w-full">
                    <Label>First name</Label>
                    <Input
                      type="text"
                      {...register("first_name", {
                        required: true,
                        value: account.first_name,
                      })}
                    />
                  </Field>
                  <Field className="flex flex-col gap-1 w-full">
                    <Label>Last name</Label>
                    <Input
                      type="text"
                      {...register("last_name", {
                        required: true,
                        value: account.last_name,
                      })}
                    />
                  </Field>
                </div>
                <Field className="flex flex-col gap-1 w-full">
                  <Label>Phone number</Label>
                  <Input
                    type="tel"
                    {...register("phone_number", {
                      required: true,
                      value:
                        account.phone_number &&
                        formatPhoneNumber(account.phone_number),
                      onChange: phoneNumberOnChange,
                    })}
                  />
                </Field>
              </>
            )}
            <div className="flex flex-row gap-4">
              <Field className="flex flex-col gap-1 w-5/12">
                <Label>Month of birth</Label>
                <Select
                  defaultValue=""
                  {...register("birth_month", { required: true })}
                >
                  <option value="" disabled hidden>
                    Month
                  </option>
                  {Array(12)
                    .fill(0)
                    .map((_, i) => {
                      const value = String(i + 1).padStart(2, "0");
                      const month = dayjs(`2024-${value}-01`).format("MMMM");
                      return (
                        <option key={value} value={value}>
                          {value} - {month}
                        </option>
                      );
                    })}
                </Select>
              </Field>
              <Field className="flex flex-col gap-1 w-4/12">
                <Label>Day of birth</Label>
                <Select
                  defaultValue=""
                  {...register("birth_day", { required: true })}
                >
                  <option value="" disabled hidden>
                    Day
                  </option>
                  {Array(31)
                    .fill(0)
                    .map((_, i) => {
                      const value = String(i + 1).padStart(2, "0");
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    })}
                </Select>
              </Field>
              <Field className="flex flex-col gap-1 w-3/12">
                <Label>Year of birth</Label>
                <Select
                  defaultValue=""
                  {...register("birth_year", { required: true })}
                >
                  <option value="" disabled hidden>
                    Year
                  </option>
                  {Array(150)
                    .fill(0)
                    .map((_, i) => {
                      const value = String(currentDate.year() - i - 13);
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    })}
                </Select>
              </Field>
            </div>
          </div>
          <MutationButton
            mutation={updateAccount}
            mutateButtonText="Confirm & continue"
            errorMessage="Failed submitting identity information. Please try again later"
          />
        </div>
      </PageBody>
    </form>
  );
};
