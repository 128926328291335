import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { Card } from "../Card";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useState } from "@Light/utils/state";
import { useCallback } from "react";
import { Detail } from "./Detail";
import { captureEvent } from "@Light/app/analytics";
import { useRate } from "@Light/utils/plan";

export type EnergyRateProps = {};

export const EnergyRate: React.FC<EnergyRateProps> = ({}) => {
  const rate = useRate();
  const isExpanded = useState(false);

  const hasCancellationFee =
    rate.early_termination_fee_dollars &&
    parseFloat(rate.early_termination_fee_dollars) > 0;

  const onClickExpand = useCallback(() => {
    isExpanded.setVal(!isExpanded.val);
    if (!isExpanded.val) {
      captureEvent("plan_details_expanded");
    } else {
      captureEvent("plan_details_collapsed");
    }
  }, [isExpanded.setVal, isExpanded.val]);

  const scaffold = useScaffold();
  return (
    <Card label="Average cost">
      <div
        className={clsx(
          "flex flex-col gap-3",
          scaffold.page.colors.text.primary,
        )}
      >
        <div className="flex flex-row items-center gap-4">
          <div className="text-xl font-medium">
            {rate.avg_cents_per_kwh_1000}¢
          </div>
          <div
            className={clsx(
              "border rounded-3xl px-2 py-0.5 font-medium text-sm",
              "text-[#363F72] border-[#D5D9EB] bg-[#F8F9FC]",
            )}
          >
            per kWh for 1000 kWh
          </div>
        </div>
        <div className={clsx("text-sm", scaffold.page.colors.text.tertiary)}>
          <span>Rates fixed for {rate.term_months} months</span>{" "}
          {hasCancellationFee && (
            <span>
              with a $
              {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
                parseFloat(rate.early_termination_fee_dollars),
              )}{" "}
              early termination fee
            </span>
          )}
        </div>
        <div
          className="flex flex-row items-center gap-2 pl-1 cursor-pointer"
          onClick={onClickExpand}
        >
          {isExpanded.val ? (
            <ChevronDownIcon className="w-5 h-5" />
          ) : (
            <ChevronRightIcon className="w-5 h-5" />
          )}
          <div className="text-sm">Plan details</div>
        </div>
      </div>
      {isExpanded.val && <Detail rate={rate} />}
    </Card>
  );
};
