import { Title } from "@Light/components/page/Title";
import { Subtitle } from "@Light/components/page/Subtitle";
import { Container } from "@Light/components/page/Container";
import { BackButton } from "@Light/components/page/header/BackButton";
import { Divided } from "@Light/components/page/Divided";
import {
  Fieldset,
  Legend,
  FieldGroup,
  Field,
  Label,
  Description,
  ErrorMessage,
} from "@Light/components/system/Fieldset";
import { Input, Select } from "@Light/components/system/Input";
import {
  Button,
  ClearButton,
  OutlineButton,
} from "@Light/components/system/Button";
import { Spinner } from "@Light/components/system/Spinner";
import { Link } from "@Light/components/system/Link";
import { MobileNavLink } from "@Light/components/system/MobileNavLink";
import { Datepicker } from "@Light/components/system/Datepicker";
import { AddressResult } from "@Light/components/enroll/PickAddress/AddressResult";
import { ServiceCard } from "@Light/components/enroll/ServiceCard";
import { Vertical } from "@Light/components/enroll/common/Vertical";
import { ConfirmRate } from "@Light/components/enroll/ConfirmRate";
import { PaymentSetup } from "@Light/components/enroll/PaymentSetup";
import { OnFilePaymentSetup } from "@Light/components/enroll/OnFilePaymentSetup";
import { EnrollmentConfirmed } from "@Light/components/enroll/EnrollmentConfirmed";
import { MenuItem } from "@Light/components/page/header/menu/MenuItem";
import { MenuHeader } from "@Light/components/page/header/menu/MenuHeader";
import { BaseMenu } from "@Light/components/page/header/menu/BaseMenu";
import { Menu } from "@Light/components/page/header/menu/Menu";
import { ServiceMenu } from "@Light/components/page/header/menu/ServiceMenu";
import { PendingServiceMenu } from "@Light/components/page/header/menu/PendingServiceMenu";
import { EnrollmentMenu } from "@Light/components/page/header/menu/EnrollmentMenu";
import { QueryLoader } from "@Light/components/app/QueryLoader";
import { RequireNoService } from "@Light/components/app/RequireNoService";
import { RequireService } from "@Light/components/app/RequireService";
import { Welcome } from "@Light/components/enroll/Welcome";
import { PickAddress } from "@Light/components/enroll/PickAddress";
import { DetailLabel } from "@Light/components/page/detail/DetailLabel";
import { DetailField } from "@Light/components/page/detail/DetailField";
import { MutationButton } from "@Light/components/page/MutationButton";
import { PageBody } from "@Light/components/page/PageBody";
import { CircleIcon } from "@Light/components/page/CircleIcon";
import { Hyperlink } from "@Light/components/page/Hyperlink";
import { EnumeratedList } from "@Light/components/page/EnumeratedList";
import { InfoItem } from "@Light/components/page/InfoItem";
import { Button as PageButton } from "@Light/components/page/Button";
import { ESIIDField } from "@Light/components/page/detail/ESIIDField";
import { ProgressBar } from "@Light/components/page/ProgressBar";
import { ServiceAddressField } from "@Light/components/page/detail/ServiceAddressField";
import { VerifyIdentity } from "@Light/components/enroll/VerifyIdentity";
import { Overlay } from "@Light/components/page/Overlay";
import { Header } from "@Light/components/page/header";
import { EmptyHeader, WithHeader } from "@Light/components/page/header/Header";
import { Offer } from "@Light/components/enroll/Offer";
import { UpdateName } from "@Light/components/account/Details/UpdateName";
import { UpdateEmail } from "@Light/components/account/Details/UpdateEmail";
import { LogoutButton } from "@Light/components/page/header/menu/LogoutButton";
import { EnrollmentConfirmedFooter } from "@Light/components/enroll/EnrollmentConfirmedFooter";
import { WithEnrollmentHeader } from "@Light/components/enroll/WithEnrollmentHeader";
import { Navigate } from "react-router-dom";

export const defaultScaffold = {
  page: {
    companyName: "ACME",
    supportEmail: "support@light.dev",
    supportPhone: "800-956-6208",
    supportHours: "",
    colors: {
      text: {
        primary: "text-[#101828]",
        secondary: "text-[#344054]",
        tertiary: "text-[#475467]",
        info: "text-blue-600",
        warn: "text-orange-600",
        error: "text-red-700",
        link: "text-slate-900",
        button: "text-slate-600 hover:text-slate-900",
        success: "text-green-600",
      },
      button: {
        background: {
          plain: "bg-[#101828] hover:bg-[#667085]",
          plainDisabled: "bg-[#667085]",
          error: "bg-[#D92D20] hover:bg-[#F97066]",
          errorDisabled: "bg-[#F97066]",
        },
        info: "bg-blue-600 hover:bg-blue-500",
      },
      border: {
        paper: "border-white",
        plain: "border-[#EAECF0]",
        info: "border-blue-600",
        warn: "border-orange-600",
        error: "border-red-600",
      },
      background: {
        info: "bg-blue-50",
        warn: "bg-orange-50",
        error: "bg-red-50",
        success: "bg-[#ECFDF3]",
      },
      hover: {
        highlight: "hover:bg-slate-100",
      },
      chart: {
        primary: "#98C9FF",
        secondary: "#3E83E8",
        unselected: "#CBD1DB",
      },
    },
    overlay: Overlay,
    title: Title,
    subtitle: Subtitle,
    hyperlink: Hyperlink,
    container: Container,
    divided: Divided,
    header: {
      withHeader: WithHeader,
      header: Header,
      emptyHeader: EmptyHeader,
      backButton: BackButton,
      menu: {
        menu: Menu,
        serviceMenu: ServiceMenu,
        pendingServiceMenu: PendingServiceMenu,
        enrollmentMenu: EnrollmentMenu,
        baseMenu: BaseMenu,
        menuHeader: MenuHeader,
        menuItem: MenuItem,
        logoutButton: LogoutButton,
      },
    },
    pageBody: PageBody,
    progressBar: ProgressBar,
    detail: {
      detailLabel: DetailLabel,
      detailField: DetailField,
      esiidField: ESIIDField,
      serviceAddressField: ServiceAddressField,
    },
    mutationButton: MutationButton,
    circleIcon: CircleIcon,
    enumeratedList: EnumeratedList,
    infoItem: InfoItem,
    button: PageButton,
  },
  app: {
    queryLoader: QueryLoader,
    requireNoService: RequireNoService,
    requireService: RequireService,
  },
  system: {
    fieldset: Fieldset,
    legend: Legend,
    fieldGroup: FieldGroup,
    field: Field,
    label: Label,
    description: Description,
    errorMessage: ErrorMessage,
    input: Input,
    select: Select,
    button: Button,
    outlineButton: OutlineButton,
    clearButton: ClearButton,
    spinner: Spinner,
    link: Link,
    mobileNavLink: MobileNavLink,
    datepicker: Datepicker,
    navigate: Navigate,
  },
  enroll: {
    addressResult: AddressResult,
    serviceCard: ServiceCard,
    common: {
      vertical: Vertical,
    },
    withEnrollmentHeader: WithEnrollmentHeader,
    steps: {
      offer: Offer,
      welcome: Welcome,
      pickAddress: PickAddress,
      confirmRate: ConfirmRate,
      verifyIdentityNeedsName: false,
      verifyIdentity: VerifyIdentity,
      paymentSetup: PaymentSetup,
      onFilePaymentSetup: OnFilePaymentSetup,
      enrollmentConfirmed: EnrollmentConfirmed,
      enrollmentConfirmedFooter: EnrollmentConfirmedFooter,
    },
  },
  dash: {},
  account: {
    details: {
      updateName: UpdateName,
      updateEmail: UpdateEmail,
    },
  },
};
